import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly4D2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/4D/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/4D/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/4D/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/4D/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/4D/5.webp`;


  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1.4,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1.4,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1.4,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1.4,
    },
    {
        src: p5,
        source: p5,
        rows: 1.7,
        cols: 2,
      }
     

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Knowledge Speaks, Wisdom Listens       
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4D Date: 5 & 6  September 2024

        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Knowledge is imparting information whereas wisdom and clarity are the patrons of learning and education"

<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The special assembly of Class 4D on Teachers’ Day was dedicated to honour the hard work, dedication and contributions of the esteemed teachers. A talk on the significance of celebrating Teachers’ Day was delivered, followed by the thought for the day. The highlight of the event was a soulful rendition that expressed the best grooming done by teachers to make the students better millennials.  As a token of appreciation, each teacher was presented with a handmade gifts -wealth out of waste in recognition of their positive influence on every student. This gesture expressed the gratitude to the teachers for their unwavering support and guidance.
        <br></br>
        On Friday, September 6 2024, the assembly began with the introduction about the significance of the Gouri Ganapathi Festival, highlighting its importance in our tradition Lord Ganapathi regarded as ‘Lord of Learning’ and Goddess Gouri who illustrates the qualities of a compassionate and effective teacher. 
        <br></br>
        The mesmerising Veena recital by a student was a beautiful rendition of classical piece dedicated to Lord Ganapathi, Goddess Gouri and the beloved teachers. The melodious strains of the Veena captivated the audience. Following the Veena recital, the students of Class 4D presented a vibrant and energetic dance performance, which was a visual treat. The performance was both lively and expressive, adding to the festive spirit of the celebration.

        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Most of the successful people are the ones who do more listening than talking."
   <br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4D2024;